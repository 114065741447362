<template>
  <div class="tripartite">
    <p>第三方账号绑定登录协议</p>

    <div class="tripartite-com">
      <p>尊敬的鸿联云会员用户：</p>
      <p>您正在开启 <strong>第三方账号绑定登录功能。</strong>  </p>
      <p><strong style="text-decoration:underline">特别提示：该功能开启后，您所授权绑定的第三方账号，通过其在第三方平台的有效登录状态，将可直接登录您绑定的鸿联云网站会员账号，且登录后所进行的所有操作，将被视为您本人通过该鸿联云账号所进行的行为，您本人将对前述行为及后果承担相应的责任。</strong></p>
      <p>
        <strong style="text-decoration:underline">如果您在了解上述提示后，仍愿意进行下一步操作的，</strong>
        请您认真细致的阅读以下《第三方账号绑定登录协议》，特别是其中以
        <strong style="text-decoration:underline">粗体下划线明显标注</strong>
        的条款，并在确认接受本协议的条款后，再进入后续程序。
      </p>
      <p><strong style="text-decoration:underline">您继续后续操作的行为，将被视为您已阅读并完全接受协议的全部约定，本协议对鸿联云和您均具有法律约束力。</strong></p>
    </div>

    <div class="tripartite-com">
      <p> <strong>第一条 定义和解释</strong></p>

      <p>1.1 第三方账号绑定登录：是指第三方账号在完成与鸿联云账号的绑定后，当在其第三方平台进行有效登录后，可以将与其绑定的鸿联云账号同步为登录状态而不用进行鸿联云账号的登录。</p>

      <p>1.2 用户：是指接受《<router-link :to="{path:'/protocol/serve'}">鸿联云平台服务协议</router-link>》、《<router-link :to="{path:'/protocol'}">法律声明及隐私权政策</router-link>》，通过自愿注册而取得鸿联云会员账号、在鸿联云网站开展活动的个人、法人或其他组织。即本协议中的“您”。</p>

      <p>1.3 鸿联云网站：是指域名为gitok.com的网站。</p>

      <p>1.4 鸿联云会员账号：是用户在鸿联云网站开展活动的唯一身份凭据，又称“鸿联云用户ID”。</p>

      <p>1.5 第三方账号：是指在鸿联云网站之外的第三方网络平台开展活动的身份凭据。</p>

      <P><strong>第二条 账号绑定、绑定登录及账号解绑</strong></P>

      <p>2.1 申请开通账号绑定功能，须符合以下条件：</p>

      <p>2.1.1 用户为授权鸿联云账号的持有人，或已获得账号持有人的授权。</p>

      <p>2.1.2 该账号处于正常状态，本身没有任何争议和纠纷，或存在与开启账号绑定功能相冲突的使用限制。</p>

      <p>2.1.3 用户已获得了第三方账号持有人的同意或授权，进行账号绑定操作。</p>

      <p>2.1.4 该绑定申请获得了拟绑定的鸿联云账号与第三方账号的账号及密码验证通过。</p>

      <p>2.2 您理解并同意，一个鸿联云账号可授权多个第三方账号绑定登录；绑定后，该等第三方账号均可以其在第三方平台的有效登录状态，直接登录您的鸿联云账号。<strong style="text-decoration:underline">您进一步理解并接受：</strong></p>

      <p><strong style="text-decoration:underline">
        2.2.1 该等第三方账号无需输入您的鸿联云账号和登录密码，即可直接登录您的鸿联云账号并进行《<router-link :to="{path:'/protocol/serve'}">鸿联云平台服务协议</router-link>》允许的一切操作，因此产生的风险与损失由您本人完全承担。
      </strong></p>

      <p><strong style="text-decoration:underline">2.2.2 鸿联云将读取该等第三方账号的账号名、是否有效登录状态等信息用于本协议项下之目的使用。因此，您在进行账号绑定操作前，应将该等信息使用情况告知第三方账号持有人并获得其认可。</strong></p>

      <p> <strong style="text-decoration:underline">2.2.3 本协议项下的账号绑定为单向绑定，您不会因此获得第三方账号的登录权限。</strong></p>

      <p><strong style="text-decoration:underline">2.2.4 本协议项下的账号绑定不会授予该等第三方账号持有人对您的鸿联云账号拥有任何权属，该等第三方账号持有人以存在账号绑定关系为由，要求行使有关鸿联云账号及其下业务、款项、权益等主张的，鸿联云将不予支持。</strong></p>

      <p>2.3 您可随时解除您的鸿联云账号与一个或多个第三方账号的绑定关系，而无须获得该等第三方账号的同意，或告知该等第三方账号绑定关系已解除。绑定关系解除后，该等第三方账号将无法登录您鸿联云账号。</p>

     <P> <strong>三、附则</strong></P>

      <p>3.1 您理解并接受，本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律，与法律规定不一致或存在冲突的，该不一致或冲突条款不具有法律约束力。</p>

      <p>3.2 就本协议内容或其执行发生任何争议，双方应进行友好协商；<strong style="text-decoration:underline">协商不成时，任一方均可向鸿联云所在地有管辖权的人民法院提起诉讼。</strong></p>

      <p style="margin-bottom: 100px">3.3 本协议如果与双方以前签署的有关条款或者鸿联云的有关陈述不一致或者相抵触的，以本协议约定为准。</p>

      <p><strong style="text-decoration:underline">您在此再次保证已经完全阅读并理解了上述第三方账号绑定登录协议，并接受上述条款的约束，自愿正式进入第三方账号绑定登录的后续流程。</strong></p>

    </div>





  </div>
</template>

<script>
export default {
  name: "tripartite"
}
</script>

<style scoped lang="scss">

.tripartite{
  width: 1280px;
  margin: 0 auto;
  background: #fff;
  padding: 48px 24px;
  >p{
    text-align: center;
    font-size: 24px;
    margin-bottom: 50px;
  }
  strong{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
  }
  >.tripartite-com{
      >p{
        font-size: 16px;
        line-height: 24px;
        text-indent: 2em;
        margin-bottom: 24px;
      }
  }
}

</style>
